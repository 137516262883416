body{
    font-family: Arial, sans-serif;
    height: 100%;
    margin: 0;
    background-color: white;
  padding: 0;
}

h1{
    font-size: 50px;
    font-weight: 600;
}

h2{
    font-size: 36px;
    font-weight: 600;
}

h3{
    font-size: 18px;
    font-weight: 600;
}

p{
    font-size: 15px;
    font-weight: 400;
}

header{
    background-color: aliceblue;
    background-image: url("bg1.png");
}

button{
    width:9rem;
    height:3rem;
    background-color:aquamarine;
    font-size: small;
    border: aquamarine;
    border-radius: 8px;
}

.HeroBanner{
    width:100%; 
    height:70vh;
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    color: #F2EDD0;
}

.first-image{
    padding-top: 6%;
    width: 40rem;
    height: auto;
}

main{
    background-color: white;
}

.intro-family{
    background-color: antiquewhite;
    background-image: url("family.png") ;
    background-repeat: no-repeat;
    text-align: center;
    color:black;
}

.card-images{
    width: 15rem;
    height: auto;
}

.cards{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding-top: 4rem;
}

.card-1{
    width:15rem;
    height: 35rem;
}

.mother-info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap:12rem;
    padding: 4rem;
}

.mother-image-class{
    display: flex;
    flex-direction: row-reverse;
}

.mother-img{
    width: 50%;
    height: auto;
}



.mother-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    text-align: left;
}

.links-mother{
    display: flex;
    flex-direction: row;
    gap:5rem;
}

.mother-desc{
    width: 30rem;
}


.acheivement{
text-align: center;
background-color: azure;
background-image: url("bgx.png") ;
background-repeat: no-repeat;
color:white;
padding: 5rem;
}

.achievement-cards{
    display: flex;
    flex-direction: column;
}

.achievement-card-images{
    width: 30rem;
    height: auto;
}

.achievement-cards-1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
}

.stats{
    background-color: beige;
    background-image: url("bgstats.png");
    background-repeat: no-repeat;
    color:white;
    
}
.stats-info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap:12rem;
    padding: 5rem;
    
}

.stats-image-class{
    display: flex;
    flex-direction: row;
}

.stats-img{
    width: 50%;
    height: auto;
}

summary{
    background-color: darkred;
    width: 20rem;
    padding: 1rem;
    color:white;
}

details{
    background-color: white;
    color:black;
    
}

.accordion-text{
    padding: 0.5rem;
    width:20rem;
}

.stats-details{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    
}

.accordion{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
}

.testimonials-img{
    width: 12rem;
    height: auto;
}

.testimonials{
    justify-content: center;
    text-align: center;
    background-color: aqua;
}

.video-gallery{
    display: flex;
    flex-direction: column;
    background-color: blanchedalmond;
    background-image: url("bgvideo.jpg");

    padding: 2rem;
}

.video-section{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
    padding: 2rem;
}

video{
    width: 100%;
}

.testimonials-img{
    width:15%;
    height: auto;
}


.image-section{
    display: flex;
    justify-content: center;
    gap: 4rem;
    padding: 2rem;
    height: 24rem;;
}

.club-card-images{
    width: 30%;
    height: auto;
}
.club-gallery-section{
    text-align: center;
    background-color: aquamarine;
    padding-bottom: 3rem;
    color:white;
    background-image: url("bgclubs.jpg");
    background-repeat: no-repeat;
}

.club-gallery{
    display: flex;
    justify-content: center;
}

.club-cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    padding-bottom: 6rem;
}

.club-text{
    text-align: center;
    width: 30%;
    position: relative;
  display: inline-block;
  
}

.club-card-images{
    display: block;
  width: 100%;
  height: auto;
}

.club-1{
        position: absolute;
        /* top: 50%;  */
        left: 50%; 
        transform: translate(-50%, -50%); 
        background-color: white; 
        color: black; 
        padding: 10px 20px; 
        font-size: 20px; 
        text-align: center;
}


.footer-content{
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: space-around;
    padding: 3rem;
}

.carousel{
    height: 60vh;
    perspective: 250px;
    padding-bottom: 5rem;
}
.carousel .carousel-item{
    width: 450px;
}
.card {
	/* position: relative; */
	margin: .5rem 0 1rem 0;
	background-color: #fff;
	width: 450px;
	height: 250px;
	color: #262626;
	border-radius: 8px;
	box-shadow: 0 8px 20px 0px rgba(0,0,0,0.2);
}
.img-area img {
	border-radius: 50%;
	border: 7px solid #fff;
	/* margin-top: 80px; */
}

.img-area {
  text-align: center;
}
.img-area img {
  width: 30%;
}

.main-text {
	padding: 8px 0;
	text-align: center;
}
/* .main-text h2 {
	font-weight: 900;
	font-size: 20px;
	margin: 0 0 20px;
	font-family: 'Alfa Slab One', cursive;
} */

.main-text p{
  font-size: 16px;
  padding: 0 35px;
}

.profile{
    text-align: center;
    color: white;
    background-image: url("bgtest.jpg");
}
